import { cn } from '@collection-platform-frontend/shared';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/solid';
import { motion, useAnimation } from 'framer-motion';
import { KeenSliderInstance, useKeenSlider } from 'keen-slider/react';
import {
  ChangeEvent,
  FC,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactPlayer from 'react-player';

import { Image, SwipeIcon, Typography } from '..';

type SliderArrowButtonProps = HTMLAttributes<HTMLDivElement> & {
  buttonType: 'prev' | 'next';
  primary?: boolean;
  target?: KeenSliderInstance | null;
};

const SliderArrowButton: FC<SliderArrowButtonProps> = ({
  buttonType,
  className,
  primary,
  target,
  ...rest
}) => {
  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (!target) {
        return;
      }

      switch (buttonType) {
        case 'prev': {
          return target.prev();
        }
        case 'next': {
          return target.next();
        }
      }
    },
    [buttonType, target],
  );

  return (
    <div
      {...rest}
      className={cn(
        'absolute top-1/2 -translate-y-1/2 rounded-full',
        'bg-white p-2 transition-all hover:bg-opacity-80 cursor-pointer shadow-3xl',
        { 'bg-opacity-10 hover:bg-opacity-5': !primary },
        {
          'left-2': buttonType === 'prev',
          'right-2': buttonType === 'next',
        },
        className,
      )}
      onClick={onClick}
    >
      {buttonType === 'prev' && (
        <ChevronLeftIcon
          className={cn('w-4 h-4', { 'text-zinc-100': !primary })}
        />
      )}
      {buttonType === 'next' && (
        <ChevronRightIcon
          className={cn('w-4 h-4', { 'text-zinc-100': !primary })}
        />
      )}
    </div>
  );
};

export type MediaSource = {
  contentType?: string | null;
  url?: string | null;
  thumbnailUrl?: string | null;
};

export type MediaSliderProps = {
  slideHeight?: number;
  slideWidth?: number;
  showThumbnails?: boolean;
  sources?: MediaSource[];
};

export const MediaSlider: FC<MediaSliderProps> = ({
  slideHeight = 900,
  slideWidth = 900,
  showThumbnails = false,
  sources = [],
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [slideSize, setSlideSize] = useState<number>();
  const [showSwipeIcon, setShowSwipeIcon] = useState<boolean>(true);
  const [muted, setMuted] = useState<boolean>(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
      setShowSwipeIcon(false);
    },
    created() {
      setLoaded(true);
      // window resizeされないと高さを受け取れないので、ロード時はスライドの高さを受け取る
      setSlideSize(
        instanceRef.current
          ? instanceRef.current?.size - thumbnailSectionHeight
          : 0,
      );
    },
  });

  // sourceの中から画像と動画のみを抽出する
  const filtered2DSource = sources.filter(
    (v) => v.contentType === 'image' || v.contentType === 'video',
  );
  const thumbnailHeight = slideWidth > 640 ? 100 : 40;
  const hasMultipleSource = filtered2DSource?.length > 1;
  const thumbnailSectionHeight =
    showThumbnails && hasMultipleSource ? thumbnailHeight : 0;
  const target = instanceRef.current;
  const slides = target?.track?.details?.slides;

  useEffect(() => {
    // window resizeの値を受け取る
    setSlideSize(slideHeight - thumbnailSectionHeight);
  }, [slideHeight, thumbnailSectionHeight]);

  const onVolumeChange = useCallback(
    (e: ChangeEvent<HTMLVideoElement>) => {
      setMuted(e.target.muted || e.target.volume <= 0);
    },
    [setMuted],
  );

  return (
    <>
      <div className="relative">
        <div ref={sliderRef} className="keen-slider">
          {filtered2DSource.map((source, i) => {
            if (
              !source.contentType ||
              !['image', 'video'].includes(source.contentType) ||
              !source.url
            ) {
              return null;
            }

            // NOTE: 動画再生コンポーネントが別のスライドで再生されてしまうため、currentSlideが動画かどうかを判定する
            const isVideoCurrentSlide =
              currentSlide === i &&
              filtered2DSource[currentSlide].contentType === 'video';

            return (
              <div
                key={i}
                className="p-4 sm:p-8 keen-slider__slide"
                style={{
                  height: slideSize,
                }}
              >
                {source.contentType === 'video' ? (
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    style={{ background: 'black' }}
                    muted={muted}
                    playing={isVideoCurrentSlide}
                    playsinline
                    autoPlay
                    controls
                    config={{
                      file: {
                        attributes: {
                          // NOTE: ダブルクリックからの動画ダウンロードを防ぐため。
                          // ref: https://github.com/cookpete/react-player/issues/323
                          onContextMenu: (e: MouseEvent) => e.preventDefault(),
                          disablePictureInPicture: true,
                          controlsList: 'nodownload',
                        },
                      },
                    }}
                    url={source.url}
                    onVolumeChange={onVolumeChange}
                  />
                ) : (
                  <Image
                    src={source.url}
                    alt={`slider-${i}`}
                    className="w-full h-full"
                    layout="fill"
                    objectFit="contain"
                  />
                )}
              </div>
            );
          })}
        </div>
        {hasMultipleSource && (
          <div
            className={cn(
              'md:hidden absolute left-1/2 bottom-2 -translate-x-1/2 flex-col text-center pointer-events-none transition-opacity',
              { 'opacity-0': !showSwipeIcon },
            )}
          >
            <SwipeIcon className="w-20 h-20 stroke-white" />
            <Typography variant="body2" className="text-zinc-100">
              Swipe
            </Typography>
          </div>
        )}
        {loaded && target && hasMultipleSource && (
          <>
            <SliderArrowButton buttonType="prev" target={target} />
            <SliderArrowButton buttonType="next" target={target} />
          </>
        )}
      </div>
      {showThumbnails && loaded && target && hasMultipleSource && (
        <div
          className="flex justify-center px-4 space-x-2 sm:space-x-4"
          style={{
            height: thumbnailSectionHeight,
            width: `(${100 % (slides?.length ?? 1)})%`,
          }}
        >
          {slides?.map((_0, i) => {
            const source = filtered2DSource[i];

            return (
              <div
                key={i}
                className="relative h-[10px] w-[10px] sm:h-[70px] sm:w-[70px]"
                onClick={() => {
                  target?.moveToIdx(i);
                }}
              >
                {source.contentType === 'video' ? (
                  <div className="relative hidden w-full h-full sm:inline-flex sm:justify-center sm:items-center">
                    <Image
                      src={source.thumbnailUrl}
                      className={cn(
                        'w-full h-full sm:inline-flex hidden pb-2',
                        {
                          'border-b-2 border-white cursor-default opacity-80':
                            currentSlide === i,
                          'hover:border-b-2 border-white opacity-50 cursor-pointer hover:opacity-80 transition-all':
                            currentSlide !== i,
                        },
                      )}
                      skeletonClassName="!animate-none !bg-gray-300 !rounded-none"
                      layout="fill"
                      objectFit="contain"
                      alt={`thumbnail-${i}`}
                    />
                    <PlayCircleIcon
                      className={cn(
                        'absolute top-0 bottom-0 left-0 right-0 w-5 h-5 m-auto text-white',
                        {
                          'cursor-default': currentSlide === i,
                          'cursor-pointer': currentSlide !== i,
                        },
                      )}
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <Image
                    src={source.url}
                    className={cn('w-full h-full sm:inline-flex hidden pb-2', {
                      'border-b-2 border-white cursor-default':
                        currentSlide === i,
                      'hover:border-b-2 border-white opacity-50 cursor-pointer hover:opacity-100 transition-all':
                        currentSlide !== i,
                    })}
                    layout="fill"
                    objectFit="contain"
                    alt={`thumbnail-${i}`}
                  />
                )}

                <button
                  className={cn(
                    'h-[10px] w-[10px] rounded-full inline-flex sm:hidden',
                    {
                      'bg-white': currentSlide === i,
                      'bg-gray-600': currentSlide !== i,
                    },
                  )}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

type ThumbnailButtonProps = {
  active?: boolean;
  sliderChangeTime: number;
  onClick: () => void;
  onAnimateComplete: () => void;
};

const ThumbnailButton: FC<ThumbnailButtonProps> = ({
  active,
  sliderChangeTime,
  onClick,
  onAnimateComplete,
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (active) {
      controls.start({ x: 0 });
    } else {
      controls.stop();
      controls.set({ x: '-100%' });
    }
  }, [active, controls]);

  return (
    <button
      className={cn(
        'w-[50px] h-[10px] rounded-full  border-2 border-white focus:outline-none overflow-hidden bg-white bg-opacity-0',
      )}
      onClick={onClick}
    >
      <motion.div
        initial={{
          x: '-100%',
        }}
        animate={controls}
        transition={
          active
            ? { duration: sliderChangeTime / 1000, ease: 'linear' }
            : { duration: 0 }
        }
        onAnimationComplete={active ? onAnimateComplete : undefined}
        className={cn(`h-full rounded-full w-full bg-white`)}
      />
    </button>
  );
};

interface SliderProps<T> {
  source: T[];
  itemRenderer: (value: T, active: boolean) => ReactNode;
  onSelect?: (value: T, index: number) => void;
}

export type HeroSliderProps<T> = SliderProps<T> & {
  sliderChangeTime?: number;
};

export const HeroSlider = <T,>({
  sliderChangeTime = 5000,
  source,
  itemRenderer,
  onSelect,
}: HeroSliderProps<T>) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    breakpoints: {
      '(min-width: 768px)': {
        slides: { origin: 'center', perView: 2, spacing: 10 },
      },
    },
    slides: {
      origin: 'center',
      perView: 1,
      spacing: 5,
    },
    loop: true,
    mode: 'snap',
  });

  const onSelectThumbnail = useCallback(
    (index: number) => {
      return () => {
        instanceRef.current?.moveToIdx(index);
      };
    },
    [instanceRef],
  );

  const onAnimateComplete = useCallback(() => {
    instanceRef.current?.next();
  }, [instanceRef]);

  const onSelectItem = useCallback(
    (item: T, index: number) => {
      return () => {
        if (index === currentSlide) {
          onSelect?.(item, index);
        } else {
          instanceRef.current?.moveToIdx(index);
        }
      };
    },
    [currentSlide, instanceRef, onSelect],
  );

  const numOfSlide = instanceRef.current?.track.details?.slides.length ?? 0;
  return (
    <>
      <div ref={sliderRef} className="keen-slider">
        {source?.map((item, index) => {
          return (
            <div
              key={index}
              className="relative keen-slider__slide"
              onClick={onSelectItem(item, index)}
            >
              {itemRenderer(item, currentSlide === index)}
            </div>
          );
        })}
      </div>
      <div className={'flex justify-center space-x-2 mt-4 mb-2'}>
        {numOfSlide > 0 &&
          [...Array(numOfSlide).keys()].map((v) => {
            return (
              <ThumbnailButton
                key={v}
                active={currentSlide === v}
                sliderChangeTime={sliderChangeTime}
                onClick={onSelectThumbnail(v)}
                onAnimateComplete={onAnimateComplete}
              />
            );
          })}
      </div>
    </>
  );
};

export type BannerSliderProps<T> = SliderProps<T>;

export const BannerSlider = <T,>({
  source,
  itemRenderer,
  onSelect,
}: BannerSliderProps<T>) => {
  const [initialized, setInitialized] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setInitialized(true);
    },
    breakpoints: {
      '(min-width: 640px)': {
        slides: { origin: 'auto', perView: 2, spacing: 10 },
      },
      '(min-width: 1080px)': {
        slides: { origin: 'auto', perView: 4, spacing: 10 },
      },
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 5,
    },
    loop: false,
    mode: 'free-snap',
  });

  const target = instanceRef.current;

  const slideOptions = instanceRef.current?.options.slides;
  const perView =
    typeof slideOptions === 'object'
      ? (slideOptions?.perView as number) ?? 0
      : 0;

  return (
    <div className="relative">
      <div ref={sliderRef} className="keen-slider">
        {source?.map((item, index) => {
          return (
            <div
              key={index}
              className="relative keen-slider__slide"
              onClick={() => onSelect?.(item, index)}
            >
              {itemRenderer(item, currentSlide === index)}
            </div>
          );
        })}
      </div>
      {initialized && target && (
        <>
          <SliderArrowButton
            className={cn({ hidden: currentSlide === 0 }, '-left-4')}
            primary
            buttonType="prev"
            target={target}
          />
          <SliderArrowButton
            className={cn(
              { hidden: source.length - perView === currentSlide },
              '-right-4',
            )}
            primary
            buttonType="next"
            target={target}
          />
        </>
      )}
    </div>
  );
};
